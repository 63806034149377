<script setup lang="ts">
defineProps<{
  tipsText?: string
}>()

onMounted(() => {
  // if (thisComponentEle.value?.parentElement) {
  //   thisComponentEle.value?.parentElement?.addEventListener('mouseenter', () => isShow.value = true)
  //   thisComponentEle.value?.parentElement?.addEventListener('mouseleave', () => isShow.value = false)
  // }
})
</script>

<template>
  <div
    class="tooltip pointer-events-none absolute top-0 opacity-0 transition-opacity-250"
    group-hover="pointer-events-auto op-100"
  >
    <slot v-if="$slots.default" />
    <template v-else>
      <div class="relative flex-center rd-2 bg-gray-4 px-4 py-3">
        <span class="ws-nowrap text-3.5 c-#fff c-op-90 font-600 lh-3">{{ tipsText }}</span>
      </div>
      <div class="h-4.5 w-full flex justify-center">
        <svg width="24" height="6" viewBox="0 0 24 6" fill="none" xmlns="http://www.w3.org/2000/svg">
          <g clip-path="url(#clip0_92_1612)">
            <path
              d="M13.2 5.1L17.8667 1.6C19.2514 0.561423 20.9357 -1.33945e-07 22.6667 -5.82818e-08L1.33333 -9.90791e-07C3.06429 -9.15129e-07 4.74856 0.561422 6.13333 1.6L10.8 5.1C11.5111 5.63333 12.4889 5.63333 13.2 5.1Z"
              fill="#333333"
            />
          </g>
          <defs>
            <clipPath id="clip0_92_1612">
              <rect width="24" height="6" fill="white" />
            </clipPath>
          </defs>
        </svg>
      </div>
    </template>
  </div>
</template>
